import React from 'react'

import { Container } from './styles'

interface Props {
  avatar: string | null
  name: string
  refCode: string
  size?: number
  sizeToggled?: number
  toggled?: boolean
}

const Avatar: React.FC<Props> = ({
  avatar,
  name = 'User',
  refCode,
  size = 30,
  sizeToggled = 30,
  toggled = false,
}) => {
  const linkAvatar = avatar
    ? `${process.env.REACT_APP_API_URL}/files/${avatar}`
    : `https://api.adorable.io/avatars/120/${refCode}.png`

  return (
    <Container size={size} sizeToggled={sizeToggled} toggled={toggled}>
      <img src={linkAvatar} alt={name} />
    </Container>
  )
}

export default Avatar
