import React from 'react'

import { useLayoutInfos } from '../../../../hooks/layoutInfos'

import { ToggleMenuProvider } from '../../../../hooks/toggleMenu'

import Burger from './Burger'
import Menu from './Menu'

import trilhaLogo from '../../../../assets/logo-mirador.png'
import { Container, Left, Center, Right } from './styles'

const Header: React.FC = () => {
  const { layoutInfos } = useLayoutInfos()

  return (
    <Container>
      <div>
        <Left>
          <img src={trilhaLogo} alt="Trilha certa" />
        </Left>
        <Center>
          <h2>{layoutInfos?.title}</h2>
          <p>{layoutInfos?.description}</p>
        </Center>
        <Right>
          <ToggleMenuProvider>
            <Burger />
            <Menu />
          </ToggleMenuProvider>
        </Right>
      </div>
    </Container>
  )
}

export default Header
