import styled, { css } from 'styled-components'
import { shade, tint, rgba } from 'polished'

interface ButtonProps {
  disabled?: boolean
}

export const Container = styled.div`

  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 420px;

  @media screen and (min-width: 610px){

    min-height: 475px;
  }

  @media screen and (min-width: 800px){

    min-height: 550px;
  }
  @media screen and (min-width: 990px){

    min-height: 650px;
  }
`


export const World = styled.div`
  width: 100%;
  max-width: 400px;
  height: 275px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  border: 1px solid ${({ theme }) => rgba(theme.colors.background, 0.15)};
  background-color: ${({ theme }) => theme.colors.background};
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
  position: relative;
  flex-direction: column;
  text-align: center;
  padding: 16px;

  img {
    position: absolute;
    height: 120px;
    top: -60px;
  }

  h2 {
    margin-bottom: 8px;
  }

  > div {
    background-color: ${({ theme }) => tint(0.05, theme.colors.background)};
    border: 1px solid ${({ theme }) => rgba(theme.colors.background, 0.15)};
    border-radius: 10px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    height: 95px;
    position: absolute;
    bottom: -60px;

    p {
      color: ${({ theme }) => theme.colors.golden};
    }

    button {
      margin-top: 0;
    }
  }

  @media screen and (min-width:610px) {
    max-width: unset;
    height: 300px;
    width: 450px;
    padding: 18px;
    img {
      height: 145px;
      top: -77.5px;
    }
  h2 {
    margin-bottom: 10px;
  }
    > div {
      height: 125px;
      bottom: -75px;
    }
  }
  @media screen and (min-width:800px) {
    height:350px;
    width: 500px;

    padding: 21px;
    img {
      height: 175px;
      top: -87.5px;
    }
  h2 {
    margin-bottom: 12px;
  }
    > div {
      height: 155px;
      bottom: -90px;
    }
  }
  @media screen and (min-width:990px) {
    height: 425px;
    width: 550px;
    padding: 24px;
    img {
      height: 190px;
      top: -95px;
    }
  h2 {
    margin-bottom: 15px;
  }
    > div {
      height:165px;
      bottom: -90px;
    }
  }
`


export const ButtonPrevNext = styled.button<ButtonProps>`
  border: none;
  background-color: transparent;
  padding: 5px;
  height: 60px;
  width: 60px;
  color: ${props => (props.disabled ? rgba(props.theme.colors.golden, 0.2) : props.theme.colors.golden)};
  transition: color 0.2s;

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}

  ${props =>
    !props.disabled &&
    css`
      &:hover {
        color: ${({ theme }) => shade(0.2, theme.colors.golden)};
      }
    `}
  svg {
    height: 50px;
    width: 50px;
  }

  @media screen and (min-width:610px){
    width: 70px;
    height: 70px;
    svg {
      width: 60px;
      height: 60px;
    }
  }


  @media screen and (min-width:800px){
    width: 80px;
    height: 80px;
    svg {
      width: 70px;
      height: 70px;
    }
  }


  @media screen and (min-width:990px){
    width: 95px;
    height: 95px;
    svg {
      width: 85px;
      height: 85px;
    }
  }
`
