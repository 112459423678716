import React, { createContext, useCallback, useState, useContext } from 'react'

import api from '../services/api'

interface WorldStats {
  points: number
  currentMissionId: string
  worldId: string
  userId: string
  id: string
}

interface WorldStatsContextData {
  worldStats: WorldStats

  setWorldStats(worldStats: WorldStats): void
  updateWorldStats(worldId: string): Promise<void>
  clearWorldStats(): void
}

const WorldStatsContext = createContext<WorldStatsContextData>(
  {} as WorldStatsContextData,
)

const WorldStatsProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<WorldStats>(() => {
    const worldStats = localStorage.getItem('@MiraGame:worldStats')

    if (worldStats) {
      return JSON.parse(worldStats)
    }
    return {} as WorldStats
  })

  const setWorldStats = useCallback(worldStats => {
    localStorage.setItem('@MiraGame:worldStats', JSON.stringify(worldStats))

    setData(worldStats)
  }, [])

  const updateWorldStats = useCallback(
    async (worldId: string) => {
      const response = await api.get(`/worlds/${worldId}/worldStats`)
      setWorldStats(response.data.worldStats)
    },
    [setWorldStats],
  )

  const clearWorldStats = useCallback(() => {
    localStorage.removeItem('@MiraGame:worldStats')
    setData({} as WorldStats)
  }, [])

  return (
    <WorldStatsContext.Provider
      value={{
        worldStats: data,
        setWorldStats,
        updateWorldStats,
        clearWorldStats,
      }}
    >
      {children}
    </WorldStatsContext.Provider>
  )
}

function useWorldStats(): WorldStatsContextData {
  const context = useContext(WorldStatsContext)
  if (!context) {
    throw new Error('useWorldStats must be used within an WorldStatsProvider')
  }
  return context
}

export { WorldStatsProvider, useWorldStats }
