import React from 'react'

import { FaStar, FaRegStar } from 'react-icons/fa'

interface StarsProps {
  stars?: number
}

const Stars: React.FC<StarsProps> = ({ stars = 0 }) => {
  switch (stars) {
    case 0: {
      return (
        <>
          <FaRegStar size={32} color="#666360" />
          <FaRegStar size={32} color="#666360" />
          <FaRegStar size={32} color="#666360" />
        </>
      )
    }
    case 1: {
      return (
        <>
          <FaStar size={32} color="#ed8b27" />
          <FaRegStar size={32} color="#666360" />
          <FaRegStar size={32} color="#666360" />
        </>
      )
    }
    case 2: {
      return (
        <>
          <FaStar size={32} color="#ed8b27" />
          <FaStar size={32} color="#ed8b27" />
          <FaRegStar size={32} color="#666360" />
        </>
      )
    }
    case 3: {
      return (
        <>
          <FaStar size={32} color="#ed8b27" />
          <FaStar size={32} color="#ed8b27" />
          <FaStar size={32} color="#ed8b27" />
        </>
      )
    }
    default: {
      return (
        <>
          <FaRegStar size={32} color="#666360" />
          <FaRegStar size={32} color="#666360" />
          <FaRegStar size={32} color="#666360" />
        </>
      )
    }
  }
}

export default Stars
