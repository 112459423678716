import React, { useEffect, useState, useCallback } from 'react'

import { FiChevronsLeft, FiChevronsRight } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'

// eslint-disable-next-line import/no-duplicates
import { isBefore, isAfter, formatDistanceToNow } from 'date-fns'

// eslint-disable-next-line import/no-duplicates
import { ptBR } from 'date-fns/locale'

import api from '../../services/api'

import Button from '../../components/Button'

import { useLayoutInfos } from '../../hooks/layoutInfos'
import { useWorldStats } from '../../hooks/worldStats'
import { useToast } from '../../hooks/toast'

import { Container, World, ButtonPrevNext } from './styles'

interface WorldData {
  id: string
  name: string
  startDate: Date
  endDate: Date
  icon: string
  background: string
}

const Dashboard: React.FC = () => {
  const { setLayoutInfos, setBackground } = useLayoutInfos()
  const { addToast } = useToast()
  const { clearWorldStats } = useWorldStats()
  const history = useHistory()

  const [worlds, setWorlds] = useState<WorldData[]>([] as WorldData[])

  const [hasPrev, setHasPrev] = useState(false)
  const [hasNext, setHasNext] = useState(false)

  const [actualWorld, setActualWorld] = useState<WorldData | null>(null)

  const [today] = useState(new Date())

  useEffect(() => {
    clearWorldStats()
    setLayoutInfos({
      title: 'Seleção de Mundo',
      description: 'selecione um mundo para jogar!',
      background: '',
    })
  }, [clearWorldStats, setLayoutInfos])

  useEffect(() => {
    async function loadWorlds(): Promise<void> {
      addToast({ type: 'info', title: 'Carregando Mundos' })
      try {
        const response = await api.get('/worlds')

        const { worlds: allWorlds } = response.data

        setWorlds(allWorlds)

        if (allWorlds.length) {
          setActualWorld(allWorlds[0])
          if (allWorlds.length > 1) {
            setHasNext(true)
          }
        } else {
          throw new Error('Não existe mundos.')
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Falha ao carregar mundos',
          description: err.message,
        })
      }
    }

    loadWorlds()
  }, [addToast])

  useEffect(() => {
    if (actualWorld && actualWorld.background) {
      setBackground(actualWorld.background)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualWorld])

  const findIndexWorld = useCallback(() => {
    return actualWorld
      ? worlds.findIndex(world => world.id === actualWorld.id)
      : 0
  }, [worlds, actualWorld])

  const handlePrevWorld = useCallback(() => {
    try {
      const index = findIndexWorld()
      const newIndex = index - 1
      if (index === 0) {
        throw new Error(`Não existe mundo anterior ao ${actualWorld?.name}`)
      }

      setActualWorld(worlds[newIndex])
      setHasPrev(newIndex > 0)
      setHasNext(newIndex < worlds.length - 1)
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Falha ao trocar mundo',
        description: err.message,
      })
    }
  }, [findIndexWorld, worlds, actualWorld, addToast])

  const handleNextWorld = useCallback(() => {
    try {
      const index = findIndexWorld()
      const newIndex = index + 1
      if (index === worlds.length - 1) {
        throw new Error(`Não existe mundo posterior ao ${actualWorld?.name}`)
      }

      setActualWorld(worlds[newIndex])
      setBackground(worlds[newIndex].background)
      setHasPrev(newIndex > 0)
      setHasNext(newIndex < worlds.length - 1)
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Falha ao trocar mundo',
        description: err.message,
      })
    }
  }, [actualWorld, addToast, findIndexWorld, setBackground, worlds])

  const handleAccessWorld = useCallback(() => {
    if (actualWorld) {
      history.push(`/world/${actualWorld.id}`, {
        world: actualWorld,
      })
    }
  }, [history, actualWorld])

  return worlds && actualWorld ? (
    <Container>
      <World>
        <img
          src={`${process.env.REACT_APP_API_URL}/files/${actualWorld.icon}`}
          alt={actualWorld.name}
        />
        <h2>{actualWorld.name}</h2>
        <p>
          {isBefore(new Date(actualWorld.startDate), today)
            ? 'Começou '
            : 'Começa '}
          {formatDistanceToNow(new Date(actualWorld.startDate), {
            addSuffix: true,
            locale: ptBR,
          })}
        </p>
        <p>
          {isAfter(new Date(actualWorld.endDate), today)
            ? 'Termina '
            : 'Terminou '}
          {formatDistanceToNow(new Date(actualWorld.endDate), {
            addSuffix: true,
            locale: ptBR,
          })}
        </p>
        <div>
          <ButtonPrevNext
            type="button"
            onClick={handlePrevWorld}
            disabled={!hasPrev}
          >
            <FiChevronsLeft />
          </ButtonPrevNext>
          {isBefore(new Date(actualWorld.startDate), today) &&
            isAfter(new Date(actualWorld.endDate), today) ? (
              <Button type="button" onClick={handleAccessWorld}>
                Acessar
              </Button>
            ) : (
              <p>Mundo indisponível</p>
            )}

          <ButtonPrevNext
            type="button"
            onClick={handleNextWorld}
            disabled={!hasNext}
          >
            <FiChevronsRight />
          </ButtonPrevNext>
        </div>
      </World>
    </Container>
  ) : (
      <br />
    )
}

export default Dashboard
