import React, { useEffect, useState } from 'react'

import api from '../../services/api'

import { useLayoutInfos } from '../../hooks/layoutInfos'
import { useToast } from '../../hooks/toast'

import { Container, RankList, UserRankItem } from './styles'

import Avatar from '../../components/Avatar'

interface RankingData {
  id: string
  user: {
    id: string
    avatar: string
    name: string
    referenceCode: string
  }
  experience: number
}

interface ResponseData {
  userPosition: number
  ranking: RankingData[]
}

const Ranking: React.FC = () => {
  const { setLayoutInfos } = useLayoutInfos()
  const { addToast } = useToast()

  const [ranking, setRanking] = useState<RankingData[]>([] as RankingData[])
  const [userPosition, setUserPosition] = useState(999)

  useEffect(() => {
    setLayoutInfos({
      title: `Ranking do jogo`,
      description: `Compare sua pontuação com a dos outros jogadores!`,
    })
  }, [setLayoutInfos])

  useEffect(() => {
    async function loadRanking(): Promise<void> {
      addToast({ type: 'info', title: 'Carregando Ranking' })
      try {
        const response = await api.get('/ranking')

        const { ranking: allRanking, userPosition: usrPos } = response.data

        setUserPosition(usrPos)
        setRanking(allRanking)
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Falha ao carregar ranking',
          description: err.message,
        })
      }
    }

    loadRanking()
  }, [addToast])

  return (
    <Container>
      <div>
        <h3>
          Você está em <strong>{userPosition}º</strong> lugar no rankig!
        </h3>
        <hr />
        <RankList>
          {ranking?.map((rank, idx) => (
            <UserRankItem>
              <strong>{idx + 1}</strong>
              <div>
                <Avatar
                  refCode={rank.user?.referenceCode}
                  size={50}
                  avatar={rank.user?.avatar}
                  name={rank.user?.name}
                />
                {rank.user.name}
              </div>
              <p>{rank.experience}XP</p>
            </UserRankItem>
          ))}
        </RankList>
      </div>
    </Container>
  )
}

export default Ranking
