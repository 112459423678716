import styled, { css } from 'styled-components'

import { shade, rgba, tint } from 'polished'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  min-height: 600px;
`

export const CardGame = styled.div`
  min-height: 350px;

  width: 900px;
  border-radius: 10px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  border: 1px solid ${({ theme }) => rgba(theme.colors.background, 0.15)};
  background-color: ${({ theme }) => theme.colors.background};
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
  position: relative;
  flex-direction: column;
  padding: 16px;

  form {
    margin-top: 40px;

    margin-bottom: 40px;
    width: 100%;
    max-width: 750px;
    h1 {
      font-size: 24px;
      text-align: center;
      margin-bottom: 32px;
      text-transform: uppercase;
    }
    small {
      display: block;
      text-align: center;
      color: #666360;
      margin-top: 8px;
    }
  }
`

export const ButtonsGroup = styled.div`
  background-color: ${({ theme }) => tint(0.05, theme.colors.background)};
  border: 1px solid ${({ theme }) => rgba(theme.colors.background, 0.15)};
  border-radius: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 480px;
  height: 106px;
  position: absolute;
  bottom: -53px;
  padding: 24px;

  > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }

  p {
    color: #ed8b27;
  }

  button {
    margin-top: 0;
    height: 56px;
    border-radius: 10px;
    border: 0;
    padding: 0 16px;
    font-weight: 500;
    transition: backgroud-color 0.2s;
    display: flex;
    align-items: center;
  }
`

export const BtnPular = styled.button`
  background: #ed8b27;

  color: #3172b7;

  &:hover {
    background: ${shade(0.1, '#ed8b27')};
  }
`

export const BtnNaoResponder = styled.button`
  background: #c53030;

  color: #fddede;

  &:hover {
    background: ${shade(0.1, '#c53030')};
  }
`

export const BtnResponder = styled.button`
  background: #2e656a;
  color: #e6fffa;

  &:hover {
    background: ${shade(0.1, '#2e656a')};
  }
`

export const BtnFinalizar = styled.button`
  width: 100%;

  background: #ebf8ff;
  color: ${props => (props.disabled ? 'rgba(49, 114, 183,0.2)' : '#3172b7')};
  display: flex;
  justify-content: center;
  align-items: center;

  height: 56px;
  text-align: center;
  padding: 0 auto;

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}

  ${props =>
    !props.disabled &&
    css`
      &:hover {
        background: ${shade(0.1, '#ebf8ff')};
      }
    `}
`
