import styled from 'styled-components'

import { shade } from 'polished'

export const Container = styled.div`
  border-radius: 10px;
  background-color: ${props => props.theme.colors.backgroundSecondary};
  height: 100%;
  width: 100%;

  > div {
    min-height: 750px;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    padding: 48px;
    display: flex;
    flex-direction: column;

    hr {
      margin: 50px 0;
      padding: 0;
      border: 1px solid ${props => props.theme.colors.secondary}

    }
  }
`
export const AvatarInput = styled.div`
  position: relative;
  width: 150px;
  align-self: center;
  img {
    height: 150px;
    width: 150px;
    border-radius: 50%;
  }

  label {
    position: absolute;
    width: 45px;
    height: 45px;
    background: ${props => props.theme.colors.primary};
    border-radius: 50%;
    right: 0;
    bottom: 0;
    border: none;
    padding: 10px;

    transition: backgroud 0.2s;

    &:hover {
      cursor: pointer;
      background: ${props => shade(0.1, props.theme.colors.primary)};
      color: ${props => shade(0.1, props.theme.colors.textSecondary)};
    }
    svg {
      color: ${props => props.theme.colors.textSecondary};
    }
    input {
      display: none;
    }
  }
`
