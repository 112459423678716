import styled, {css} from 'styled-components';
import { shade } from 'polished'



interface ButtonProps {
  disabled?: boolean
}

interface CardProps {
  isCurrent?: boolean
  maxPlayedTimes?: boolean
}

export const Container = styled.div`
  height:100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

`


export const Card = styled.div<CardProps>`
  width: 480px;
  min-height: 350px;
  border-radius: 10px;
  background-color: ${props => props.isCurrent ? '#e6fffa' : '#f4ede8'};
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #232129;
  border: 5px solid ${props => props.isCurrent ? 'rgba(46, 101, 106,0.5)' : 'rgba(49,46,56,0.15)'};

  box-shadow: 2px 2px 8px rgba(0,0,0,0.5);
  position: relative;
  flex-direction:column;
  padding: 16px;

  header {
    border: 2px solid #ed8b27;
    border-radius: 50%;
    width: 100px;
    height: 100px;

    padding: 2px;
    > div{
      border: 1px double #232129;
      border-radius: 50%;
      width:100%;
      height:100%;
      display:flex;
      justify-content: center;
      align-items: center;

      h1 {
        font-size: 48px;
      }
    }

  }
  main {
    text-align: center;
    margin-bottom: 16px;
  }

  > button {
    background: ${props => props.maxPlayedTimes ? 'rgba(46, 101, 106,0.2)' : '#2e656a'};
    color: #e6fffa;
    padding: 24px 48px;
    border:0;
    border-radius:10px;
    margin-top:24px;
    font-size: 20px;

    ${props => props.maxPlayedTimes && css`
      cursor: not-allowed;
    `}

    ${props => !props.maxPlayedTimes && css`
      &:hover{
        background: ${shade(0.1, '#2e656a')}
      }
    `}

    & + p{
      margin-top: 16px;
      color: #c53030;
    }
  }

  & + div {
    margin-top: 32px;
  }
`
