import React, { useCallback, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import api from '../../../../services/api'

import CardInfo from '../../../../components/CardInfo'

import { useToast } from '../../../../hooks/toast'
import { useGameStats } from '../../../../hooks/gameStats'
import { useWorldStats } from '../../../../hooks/worldStats'
import { useMissionStats } from '../../../../hooks/missionStats'

import { Container, CardGame } from './styles'

interface QuestionData {
  id: string
  quizMissionId: string
  question: string
}

interface GameData {
  questions: QuestionData[]
}

interface QuizMission {
  id: string
  missionId: string
}
interface OptionData {
  id: string
  isAnswer: boolean
  option: string
  quizMissionQuestionId: string
}

const Quiz: React.FC<GameData> = ({ questions }) => {
  const { missionId, worldId } = useParams()
  const { addToast } = useToast()

  const { setGameStats } = useGameStats()
  const { setWorldStats } = useWorldStats()
  const { setMissionStats } = useMissionStats()

  const [gameStarted, setGameStarted] = useState(false)

  const [ultimo, setUltimo] = useState(false)
  const [respostas, setRespostas] = useState<number[]>([])
  const [options, setOptions] = useState<OptionData[]>([])

  const [noQuestions, setNoQuestions] = useState(false)
  const [passouTodas, setPassouTodas] = useState(false)
  const [atual, setAtual] = useState(0)

  useEffect(() => {
    if (questions.length > 0) {
      setUltimo(questions.length === 1)
      setGameStarted(true)
      setNoQuestions(false)
    } else {
      setNoQuestions(true)
      setGameStarted(false)
    }
  }, [questions])

  const loadOptions = useCallback(async (): Promise<void> => {
    if (gameStarted && !noQuestions) {
      const response = await api.get(
        `/worlds/${worldId}/missions/${missionId}/questions/${questions[atual].id}/options`,
      )
      const { options: allOptions } = response.data

      setOptions(allOptions)
    }
  }, [gameStarted, noQuestions, worldId, missionId, questions, atual])

  useEffect(() => {
    loadOptions()
  }, [loadOptions])

  const changeAtual = useCallback((): void => {
    if (passouTodas) {
      setUltimo(true)
    } else {
      if (atual + 2 >= questions.length) {
        setPassouTodas(true)
      }
      setAtual(state => state + 1)
    }
  }, [atual, passouTodas, questions])

  const handleNewAnswer = useCallback(
    async (answeredOptionId: string): Promise<void> => {
      const response = await api.post(
        `/worlds/${worldId}/missions/${missionId}/questions/${questions[atual].id}/answers`,
        { answer: answeredOptionId },
      )

      const { missionStats, userGameStats, worldStats } = response.data

      setGameStats(userGameStats)
      setWorldStats(worldStats)
      setMissionStats(missionStats)

      setRespostas(state => [...state, atual])

      changeAtual()
    },
    [
      atual,
      changeAtual,
      missionId,
      questions,
      setGameStats,
      setMissionStats,
      setWorldStats,
      worldId,
    ],
  )

  return gameStarted ? (
    <Container>
      <CardInfo
        h4="Esse jogo é um quiz."
        p="Escolha uma das alternativas para responder as perguntas."
      />
      <CardGame>
        <h3>{questions[atual].question}</h3>
        <div>
          {options &&
            options.map(option => (
              <button
                key={option.id}
                type="button"
                onClick={() => handleNewAnswer(option.id)}
              >
                {' '}
                {option.option}
              </button>
            ))}
        </div>
        <small>
          Você respondeu {respostas.length} de {questions.length} questões
        </small>
      </CardGame>
    </Container>
  ) : (
      <h1>Carregando...</h1>
    )
}
export default Quiz
