/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { FiPlay } from 'react-icons/fi'
import { Form } from '@unform/web'

import api from '../../../../services/api'

import Input from '../../../../components/Input'
import CardInfo from '../../../../components/CardInfo'

import { useToast } from '../../../../hooks/toast'
import { useGameStats } from '../../../../hooks/gameStats'
import { useWorldStats } from '../../../../hooks/worldStats'
import { useMissionStats } from '../../../../hooks/missionStats'

import {
  Container,
  CardGame,
  ButtonsGroup,
  BtnPular,
  BtnResponder,
  BtnFinalizar,
} from './styles'

interface QuestionData {
  id: string
  missionId: string
  question: string
  nameShuffled: string
}

interface GameData {
  questions: QuestionData[]
}

interface AnagramAnswerData {
  word: string
}

interface AnswerObjectData {
  questionId: string
  answer: string
  missionId: string
  worldId: string
}

const Anagram: React.FC<GameData> = ({ questions }) => {
  const { missionId, worldId } = useParams()
  const { addToast } = useToast()

  const { setGameStats } = useGameStats()
  const { setWorldStats } = useWorldStats()
  const { setMissionStats } = useMissionStats()

  const [palavra, setPalavra] = useState('')
  const [question, setQuestion] = useState('')

  const [respostas, setRespostas] = useState<number[]>([])

  const [ultimo, setUltimo] = useState(false)

  const [atual, setAtual] = useState(0)

  const [passouTodas, setPassouTodas] = useState(false)

  const [faltaResponder, setFaltaResponder] = useState<number[]>([])

  const [textValue, setTextValue] = useState('')

  useEffect(() => {
    if (questions.length) {
      setPalavra(questions[atual].nameShuffled)
      setQuestion(questions[atual].question)
    }
  }, [questions, setPalavra, atual, setQuestion])

  const setObjResp = useCallback(
    (answer: string): AnswerObjectData => {
      return {
        questionId: questions[atual].id,
        answer,
        missionId,
        worldId,
      }
    },
    [atual, missionId, questions, worldId],
  )

  const changeAtual = useCallback(
    (pulou = false): void => {
      if (passouTodas) {
        // caso já tenha passado todas perguntas, atual não pode só somar.
        if (faltaResponder.length) {
          setAtual(faltaResponder[0])

          const newFalta = faltaResponder
          newFalta.shift()
          if (pulou) newFalta.push(atual)
          setFaltaResponder([...newFalta])
        } else {
          setUltimo(true)
        }
      } else {
        if (atual + 2 >= questions.length) {
          setPassouTodas(true)
        }
        setAtual(state => state + 1)
      }
    },
    [atual, faltaResponder, passouTodas, questions],
  )

  const handleFinalizar = useCallback(() => {
    // chamar final
    addToast({
      type: 'success',
      title: 'Missão finalizada!',
      description: 'Você terminou essa missão.',
    })
  }, [addToast])

  const handleSubmit = useCallback(async (): Promise<void> => {
    try {
      if (textValue === '') {
        addToast({
          type: 'error',
          title: 'Erro ao salvar resposta',
          description: 'Você precisa preencher a resposta.',
        })
      } else {
        const obj = setObjResp(textValue)

        const response = await api.post(
          `/worlds/${worldId}/missions/${missionId}/questions/${questions[atual].id}/answers`,
          obj,
        )

        const { missionStats, userGameStats, worldStats } = response.data

        setGameStats(userGameStats)
        setWorldStats(worldStats)
        setMissionStats(missionStats)

        setRespostas(state => [...state, atual])

        setTextValue('')
        changeAtual()

        if (ultimo) {
          handleFinalizar()
        }
      }
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao salvar resposta',
        description: err.message,
      })
    }
  }, [
    addToast,
    atual,
    changeAtual,
    missionId,
    questions,
    setObjResp,
    textValue,
    ultimo,
    worldId,
  ])

  const handlePular = useCallback(() => {
    setTextValue('')
    setFaltaResponder([...faltaResponder, atual])
    changeAtual(true)
  }, [setFaltaResponder, atual, changeAtual])

  return (
    <Container>
      <CardInfo
        h4="Esse jogo é um anagrama. desembaralhe a palavra abaixo para acertar!"
        p="Palavras compostas podem estar com a ordem invertida"
      />
      <CardGame>
        <div>
          <Form onSubmit={handleSubmit}>
            <h3>{question ?? 'Desembaralhe a palavra abaixo:'}</h3>
            <h1>{palavra}</h1>
            <Input
              required
              name="word"
              icon={FiPlay}
              type="word"
              onChange={e => setTextValue(e.target.value)}
              value={textValue}
              placeholder="Digite a palavra correta"
            />
          </Form>
          <small>
            Você respondeu {respostas.length} de {questions.length} questões
          </small>
          <ButtonsGroup>
            <div>
              <BtnPular type="button" onClick={handlePular} disabled={ultimo}>
                Pular
              </BtnPular>
              <BtnResponder type="button" onClick={() => handleSubmit()}>
                Responder
              </BtnResponder>
            </div>
            {/* <BtnFinalizar type="button" disabled={!(questions.length === 1)}>
              Finalizar
  </BtnFinalizar> */}
          </ButtonsGroup>
        </div>
      </CardGame>
    </Container>
  )
}

export default Anagram
