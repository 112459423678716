import styled from 'styled-components'
import { shade, tint } from 'polished'

import { Container as AvatarContainer } from '../../../components/Avatar/styles'
interface ContainerProps {
  background?: string
}

export const Container = styled.div<ContainerProps>`
  min-height: 100vh;
  padding-top: 100px;
  background: url(${props => props.theme.colors.background})
  no-repeat center center;
  color: ${props => props.theme.colors.text}
`
export const OutHeader = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  min-height: calc(100vh - 100px);
`

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 1600px;

  padding: 20px 15px;

  @media screen and (min-width: 990px) {
    flex-direction: row;
  }

  @media screen and (min-width: 1200px) {
    padding: 30px 25px;
  }
  @media screen and (min-width: 1600px) {
    padding: 45px 35px;
  }

`


export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75px;
  padding: 5px 0;

  margin: 5px 0px;

  img {
    max-height: 65px;
    max-width: 300px;
  }

  @media screen and (min-width:610px){
    height: 85px;
    padding: 7.5px 0px;
    margin: 7.5px 0px;

    img {
      max-height: 70px;
    }
  }

  @media screen and (min-width:800px){
    height: 90px;
    img {
      max-height: 75px;
    }
  }

  @media screen and (min-width:990px){
    height: 100px;
    padding: 10px 0px;
    margin: 10px 0px;
    img {
      max-height: 80px;
    }
  }
`
