import styled from 'styled-components'
import { shade, rgba } from 'polished'

import ReactPlayer from 'react-player'
export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  min-height: 750px;
`

export const Player = styled(ReactPlayer)`
  max-width: 800px;
  height: 450px;
`

export const CardVideo = styled.div`
  min-height: 550px;

  width: 900px;
  border-radius: 10px;
  background-color: #f4ede8;
  display: flex;
  align-items: center;
  color: #232129;
  border: 5px solid rgba(49, 46, 56, 0.15);
  position: relative;
  justify-content: space-between;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
  position: relative;
  flex-direction: column;
  padding: 32px;
`

export const CardGame = styled.div`
  min-height: 500px;

  width: 900px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  border: 1px solid ${({ theme }) => rgba(theme.colors.background, 0.15)};
  background-color: ${({ theme }) => theme.colors.background};
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
  position: relative;
  flex-direction: column;
  padding: 32px;

  small {
    color: #666360;
    display: block;
    position: absolute;
    bottom: 32px;
  }

  h1 {
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
  }
  h3 {
    white-space: break-spaces;
  }

  > div {
    &:before {
      content: '';
      width: 100%;
      display: block;
      height: 0px;
      border: 0.5px solid #666360;
      margin: 32px 0;
    }

    &:after {
      content: '';
      width: 100%;
      display: block;
      height: 0px;
      border: 0.5px solid #666360;
      margin: 32px 0;
    }

    width: 100%;
    display: flex;
    flex-direction: column;

    button {
      background: #2e656a;
      height: 56px;
      border-radius: 10px;
      border: 0;
      padding: 0 16px;
      color: #3172b7;
      font-weight: 700;
      transition: backgroud-color 0.2s;
      display: flex;
      align-items: center;
      color: #e6fffa;

      &:hover {
        background: ${shade(0.1, '#2e656a')};
      }

      & + button {
        margin-top: 16px;
      }
    }
  }
`
