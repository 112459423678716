import React, { createContext, useCallback, useState, useContext } from 'react'

interface LayoutInfos {
  title: string
  description?: string
  background?: string
}

interface LayoutInfosContextData {
  layoutInfos: LayoutInfos

  setBackground(background: string): void
  setLayoutInfos(layoutInfos: LayoutInfos): void
  clearLayoutInfos(): void
}

const LayoutInfosContext = createContext<LayoutInfosContextData>(
  {} as LayoutInfosContextData,
)

const LayoutInfosProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<LayoutInfos>(() => {
    const gameStats = localStorage.getItem('@MiraGame:layoutInfos')

    if (gameStats) {
      return JSON.parse(gameStats)
    }
    return {} as LayoutInfos
  })

  const setLayoutInfos = useCallback(layoutInfos => {
    const { background } = layoutInfos ?? data
    localStorage.setItem(
      '@MiraGame:layoutInfos',
      JSON.stringify({ ...layoutInfos, background }),
    )
    setData({ ...layoutInfos, background })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const clearLayoutInfos = useCallback(() => {
    setData({} as LayoutInfos)
  }, [])

  const setBackground = useCallback(
    background => {
      localStorage.setItem(
        '@MiraGame:layoutInfos',
        JSON.stringify({ ...data, background }),
      )
      setData({ ...data, background })
    },
    [data],
  )

  return (
    <LayoutInfosContext.Provider
      value={{
        layoutInfos: data,
        setBackground,
        setLayoutInfos,
        clearLayoutInfos,
      }}
    >
      {children}
    </LayoutInfosContext.Provider>
  )
}

function useLayoutInfos(): LayoutInfosContextData {
  const context = useContext(LayoutInfosContext)
  if (!context) {
    throw new Error('useLayoutInfos must be used within an LayoutInfosProvider')
  }
  return context
}

export { LayoutInfosProvider, useLayoutInfos }
