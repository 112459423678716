import styled from 'styled-components'
import { shade } from 'polished'

interface BtnProps {
  backgroundColor?: string
  textColor?: string
}

export const Container = styled.button<BtnProps>`
  background: ${props => props.backgroundColor ?? '#ed8b27'};
  height: 56px;
  border-radius: 10px;
  border: 0;
  padding: 0 16px;
  color: ${props => props.textColor ?? '#3172b7'};
  margin-top: 16px;
  font-weight: 700;
  transition: backgroud-color 0.2s;
  display: flex;
  align-items: center;

  &:hover {
    background: ${props => shade(0.1, props.backgroundColor ?? '#ed8b27')};
  }
`
