const videoPlatforms =
  process.env.NODE_ENV === 'development'
    ? {
      /** desenvolvimento */
      vimeo: '496ef4ae-39c4-4a23-be38-ae48e292cd14',
      youtube: '2d6e2cca-88e8-4707-a195-531b0f57d8ea',
      interno: 'bfebebbe-5038-443c-8293-a94208753bd4',
    }
    : {
      /** produção */
      vimeo: 'efb899c4-ac84-4d1b-b7c8-e2ca1ba9e413',
      youtube: '27cdd4ac-8871-4f09-9665-84f86aafaa23',
      interno: '309d0203-64de-43a3-8876-82daf1560a85',
    }

export default videoPlatforms
