import React, { useRef, useCallback } from 'react'
import { FiMail, FiLock, FiUser } from 'react-icons/fi'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { useHistory, useLocation } from 'react-router-dom'

import * as Yup from 'yup'

import getValidationErrors from '../../utils/getValidationErrors'

import { useAuth } from '../../hooks/auth'
import { useToast } from '../../hooks/toast'

import { Container, Formularios } from './styles'

import trilhaLogo from '../../assets/logo-mirador.png'

import Input from '../../components/Input'
import Button from '../../components/Button'

interface SignInFormData {
  email: string
  password: string
}
interface SignUpFormData {
  email: string
  name: string
}

const SignIn: React.FC = () => {
  const formLoginRef = useRef<FormHandles>(null)
  const formRegisterRef = useRef<FormHandles>(null)
  const history = useHistory()

  const { state }: { state: { email: string } } = useLocation()
  const { signIn } = useAuth()
  const { addToast } = useToast()

  const handleLoginSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formLoginRef.current?.setErrors({})

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail é obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().required('Senha Obrigatória'),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        await signIn({ email: data.email, password: data.password })
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formLoginRef.current?.setErrors(errors)
        }
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description:
            'Ocorreu um erro ao fazer login, cheque seus dados e tente novamente.',
        })
      }
    },
    [signIn, addToast],
  )

  const handleRegisterSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        formRegisterRef.current?.setErrors({})

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
          email: Yup.string()
            .required('E-mail é obrigatório')
            .email('Digite um e-mail válido'),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        history.push('/signup', { name: data.name, email: data.email })
      } catch (err) {
        formRegisterRef.current?.setErrors(getValidationErrors(err))
      }
    },
    [history],
  )

  return (
    <Container>
      <header>
        <img src={trilhaLogo} alt="Trilha Certa" />
      </header>
      <Formularios>
        <Form
          ref={formLoginRef}
          onSubmit={handleLoginSubmit}
          initialData={{
            email: state?.email,
          }}
        >
          <h1>Faça seu login</h1>
          <Input name="email" icon={FiMail} type="email" placeholder="E-mail" />
          <Input
            name="password"
            icon={FiLock}
            type="password"
            placeholder="Senha"
          />
          <Button type="submit">Entrar</Button>
          {
            // <a href="forgot">Esqueci minha senha</a>
          }
        </Form>
        <Form ref={formRegisterRef} onSubmit={handleRegisterSubmit}>
          <h1>Faça seu cadastro</h1>
          <Input name="name" icon={FiUser} type="text" placeholder="Nome" />
          <Input name="email" icon={FiMail} type="email" placeholder="E-mail" />
          <Button type="submit">Continuar</Button>
        </Form>
      </Formularios>
    </Container>
  )
}

export default SignIn
