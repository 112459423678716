const missionType =
  process.env.NODE_ENV === 'development'
    ? {
      /** desenvolvimento */
      anagramaId: 'b0d14d7d-c81a-4538-8a0f-183b0f9f2fdd',
      videoQuizId: '78bc1df1-0942-4917-8d62-7c3cf004ab67',
      artigoQuizId: 'ae67b86e-915e-4d95-893a-e87c0f15b511',
      palavraCruzadaId: '3ce2cbaf-4dab-4b55-9a44-fbd49e427f61',
      textoLivreId: '60ee2f5d-4395-4579-93d6-d91c85399479',
      quizId: '3a8dbbb6-7d7e-43fa-a694-48514667155b',
    }
    : {
      /** produção */
      anagramaId: '66b67484-216d-4134-8f59-1fa653f29eb5',
      videoQuizId: 'd98826aa-6fec-464a-8352-b0864bae85ec',
      artigoQuizId: '76c1cd0d-b5fa-4d60-b5b4-09f205257da5',
      palavraCruzadaId: '6293dba9-a14d-4973-af5e-a2c9f5ebf2c7',
      textoLivreId: 'b3a0272d-01e2-47f6-80fa-2665b6cf48f0',
      quizId: 'e28164c8-c331-4d5e-9cbb-1113c5fb63e0',
    }

export default missionType
