import React from 'react'
import { useLayoutInfos } from '../../../hooks/layoutInfos'

import Header from './Header'
import Main from './Main'
import Sidebar from './Sidebar'

import { Container, OutHeader, Content, Footer } from './styles'

import trilhaLogo from '../../../assets/logo-mirador.png'

const Logged: React.FC = ({ children }) => {
  const { layoutInfos } = useLayoutInfos()

  return (
    <Container background={layoutInfos.background}>
      <Header />
      <OutHeader>
        <Content>
          <Sidebar />
          <Main>{children}</Main>
        </Content>
        <Footer>
          <img src={trilhaLogo} alt="Logo" />
        </Footer>
      </OutHeader>
    </Container>
  )
}

export default Logged
