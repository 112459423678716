import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { FiChevronLeft } from 'react-icons/fi'

import api from '../../services/api'

import { useMissionStats } from '../../hooks/missionStats'
import { useGameStats } from '../../hooks/gameStats'
import { useToast } from '../../hooks/toast'

import Button from '../../components/Button'

import PlayGame from './PlayGame'

import { Container, CardFinalizou, GameStats } from './styles'

import coinImage from '../../assets/coin.svg'
import diamondImage from '../../assets/diamond.svg'
import xpImage from '../../assets/xp.svg'

interface MissionData {
  name: string
  id: string
  missionTypeId: string
  missionType: MissionTypeData
}

interface MissionTypeData {
  name: string
  id: string
}

interface OldUserGameStats {
  experience: number
  coins: number
  diamonds: number
}

const Game: React.FC = () => {
  const history = useHistory()
  const { addToast } = useToast()
  const { missionId, worldId } = useParams()

  const { setMissionStats, missionStats } = useMissionStats()
  const { gameStats } = useGameStats()

  const [oldGameStats, setOldGameStats] = useState<OldUserGameStats | null>(
    null,
  )

  const [questions, setQuestions] = useState([])
  const [mission, setMission] = useState<MissionData | null>(null)

  const [finalizou, setFinalizou] = useState(false)

  useEffect(() => {
    if (missionStats.finalizedAt) {
      setFinalizou(true)
    }
  }, [missionStats])

  useEffect(() => {
    if (gameStats) {
      setOldGameStats({
        experience: gameStats.experience,
        coins: gameStats.coins,
        diamonds: gameStats.diamonds,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function loadQuestions(): Promise<void> {
      try {
        const response = await api.get(
          `/worlds/${worldId}/missions/${missionId}/questions`,
        )
        const {
          mission: thisMission,
          missionStats: startedMissionStats,
          questions: allQuestions,
        } = response.data

        setMission(thisMission)
        setMissionStats(startedMissionStats)
        setQuestions(allQuestions)

        setFinalizou(false)
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao carregar missão',
          description: err.message,
        })
        history.push(`/world/${worldId}`)
      }
    }

    loadQuestions()
  }, [addToast, history, missionId, setMissionStats, worldId])
  return (
    mission && (
      <Container>
        {finalizou ? (
          <CardFinalizou>
            <div>
              <h1>Você finalizou essa missão!</h1>
              <p>Veja sua premiação</p>
              {oldGameStats && (
                <GameStats>
                  <li>
                    <img src={xpImage} alt="XP" />{' '}
                    {gameStats?.experience - oldGameStats?.experience}
                  </li>

                  <li>
                    <img src={coinImage} alt="coin" />{' '}
                    {gameStats?.coins - oldGameStats?.coins}
                  </li>

                  <li>
                    <img src={diamondImage} alt="diamond" />{' '}
                    {gameStats?.diamonds - oldGameStats?.diamonds}
                  </li>
                </GameStats>
              )}
              <h3>
                Você ganhou {missionStats.points} pontos para o ranking do mundo
              </h3>
              <Button onClick={() => history.push(`/world/${worldId}`)}>
                <FiChevronLeft size={24} />
                Voltar para o mundo
              </Button>
            </div>
          </CardFinalizou>
        ) : (
            <PlayGame
              missionId={mission.id}
              questions={questions}
              missionTypeId={mission.missionTypeId}
            />
          )}
        <Button
          backgroundColor="#ebf8ff"
          textColor="#3172b7"
          onClick={() => history.push(`/world/${worldId}`)}
        >
          <FiChevronLeft size={24} />
          Voltar para o mundo
        </Button>
      </Container>
    )
  )
}
export default Game
