export default {
  title: 'light',
  colors: {
    primary: '#FDA10D',
    secondary: '#EE9608',
    tertiary: '#DE8A02',

    background: '#FCE1C5',
    backgroundSecondary: '#F9BC8B',
    backgroundTertiary: '#F7A164',

    placeholder: '#666360',
    text: '#333',
    textSecondary: '#F5F5F5',

    golden: '#B67102', /*'#F29602',*/

    error: '#fddede',
    errorText: '#C53030',
  },
}


