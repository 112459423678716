

import styled from 'styled-components';

interface ContainerProps {
  open: boolean
}

export const Container = styled.button<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: ${({ open }) => (open ? -1 : 99)};
  position: relative;

  height: 35px;
  width: 40px;
  padding: 5px;

  transition: all 0.3s linear;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }

  div {
    width: 30px;
    height: 5px;
    background: ${({ theme }) => theme.colors.text};
    border-radius: 15px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;


    opacity: ${({ open }) => (open ? '0' : '1')};
    transform: ${({ open }) => (open ? 'translateX(300px)' : 'translateX(0)')};

    /*:first-child {
      transform: ${({ open }) => (open ? 'rotate(55deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateY(30px)' : 'translateY(0)')};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-55deg)' : 'rotate(0)')};
    }*/
  }
`;
