import React from 'react'

import { Container } from './styles'

interface CardInfoProps {
  h4: string
  p: string
}

const CardInfo: React.FC<CardInfoProps> = ({ h4, p }) => {
  return (
    <Container>
      <h4>{h4}</h4>
      <p>{p}</p>
    </Container>
  )
}

export default CardInfo
