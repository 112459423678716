import styled from 'styled-components';

import { shade } from 'polished'

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  max-width: 990px;
  width: 80%;
  margin: 0 auto;
  padding-top:15px;

  color: ${props => props.theme.colors.text};

  header {
    justify-content: center;
    display:flex;

    img {
      max-width: 300px;
    }
  }

`;

export const Formularios = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin:15vh 0;

  @media screen and (max-width:768px){

    grid-gap: 45px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;

  }

form{
  flex:1;
  display:flex;
  flex-direction: column;
  text-align: center;

  @media screen and (max-width:768px){

    & + form:before {
      content: '';
      width: 100%;
      height: 0px;
      border: 0.5px solid ${props => props.theme.colors.placeholder};
      margin-bottom:45px;
    }

  }


  h1{
    margin-bottom: 24px;
  }



  a {
    color: ${props => props.theme.colors.text};
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    &:hover {
      color:${props => shade(0.2, props.theme.colors.text)}
    }

  }
}
`
