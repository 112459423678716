import React, { useEffect, useRef, useCallback, ChangeEvent } from 'react'
import { format } from 'date-fns-tz'

import {
  FiArrowLeft,
  FiMail,
  FiUser,
  FiLock,
  FiCalendar,
  FiPhone,
  FiTerminal,
  FiCamera,
} from 'react-icons/fi'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'

import * as Yup from 'yup'

import { useToast } from '../../hooks/toast'

import api from '../../services/api'

import getValidationErrors from '../../utils/getValidationErrors'

import { useLayoutInfos } from '../../hooks/layoutInfos'
import { useAuth } from '../../hooks/auth'

import { Container, AvatarInput } from './styles'

import Input from '../../components/Input'
import Button from '../../components/Button'

interface UpdateProfileFormDate {
  name: string
  email: string
  cpf: string
  birthdate: string
  phone: string
}

const Profile: React.FC = () => {
  const { setLayoutInfos } = useLayoutInfos()
  const { user, updateProfile } = useAuth()
  const formRef = useRef<FormHandles>(null)

  const { addToast } = useToast()

  useEffect(() => {
    setLayoutInfos({
      title: `Perfil`,
      description: `Altere seu perfil`,
    })
  }, [setLayoutInfos])

  const handleSubmit = useCallback(
    async (data: UpdateProfileFormDate) => {
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
          email: Yup.string()
            .required('E-mail é obrigatório')
            .email('Digite um e-mail válido'),
          oldPassword: Yup.string().required('Confirme sua senha'),
          password: Yup.string(),
          passwordConfirm: Yup.string().oneOf(
            [Yup.ref('password')],
            'As senhas digitadas não conferem',
          ),
          cpf: Yup.string().required('CPF é obrigatório'),
          birthdate: Yup.date()
            .min(new Date(1900, 0, 1), 'Deve ser maior que 01/01/1900')
            .max(new Date(), 'Deve ser menor que a data de hoje.')
            .required('Data de nascimento obrigatória'),
          phone: Yup.string()
            .min(14, 'Deve ter ao menos 10 algarismos')
            .max(15, 'Deve ter até 11 algarismos'),
          referralCode: Yup.string(),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        const response = await api.patch('/users/profile', data)

        const { user: updatedUser } = response.data

        updateProfile(updatedUser)

        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description: 'Seu perfil foi atualizado.',
        })
        formRef.current?.setFieldValue('oldPassword', '')
        formRef.current?.setFieldValue('password', '')
        formRef.current?.setFieldValue('passwordConfirm', '')
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)
        }
        addToast({
          type: 'error',
          title: 'Erro ao atualizar cadastro',
          description: err.response?.data?.message ?? err.message,
        })
      }
    },
    [addToast, updateProfile],
  )

  const handleAvatarChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const data = new FormData()
        data.append('avatar', e.target.files[0])

        api.patch('/users/avatar', data).then(response => {
          const { user: updatedUser } = response.data

          updateProfile(updatedUser)

          addToast({
            type: 'success',
            title: 'Avatar atualizado!',
            description: 'Seu avatar foi atualizado.',
          })
        })
      }
    },
    [addToast, updateProfile],
  )

  return (
    <Container>
      <div>
        <AvatarInput>
          {user?.avatar ? (
            <img
              src={`${process.env.REACT_APP_API_URL}/files/${user.avatar}`}
              alt={user.name}
            />
          ) : (
              <img
                src={`https://api.adorable.io/avatars/120/${user?.referenceCode}.png`}
                alt={user?.name}
              />
            )}
          <label htmlFor="avatar">
            <FiCamera size={25} />
            <input
              type="file"
              name=""
              id="avatar"
              onChange={handleAvatarChange}
            />
          </label>
        </AvatarInput>
        <hr />
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            name: user?.name,
            email: user?.email,
            cpf: user?.cpf,
            birthdate: format(new Date(user?.birthdate), 'yyyy-MM-dd'),
            phone: user?.phone,
          }}
        >
          <Input name="name" icon={FiUser} type="text" placeholder="Nome" />
          <Input name="email" icon={FiMail} type="email" placeholder="E-mail" />
          <Input
            name="cpf"
            mask="cpf"
            icon={FiMail}
            type="tel"
            placeholder="CPF"
          />
          <Input
            name="birthdate"
            icon={FiCalendar}
            type="date"
            min="1900-00-01"
            placeholder="Data de nascimento"
          />
          <Input
            name="phone"
            icon={FiPhone}
            type="tel"
            mask="phone"
            placeholder="Telefone com DDD"
          />
          <Input
            name="oldPassword"
            icon={FiLock}
            type="password"
            placeholder="Senha"
          />
          <hr />
          <Input
            name="password"
            icon={FiLock}
            type="password"
            placeholder="Nova Senha"
          />
          <Input
            name="passwordConfirm"
            icon={FiLock}
            type="password"
            placeholder="Repetir Nova Senha"
          />

          <Button type="submit">Salvar Alterações</Button>
        </Form>
      </div>
    </Container>
  )
}

export default Profile
