import React, { useCallback, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import api from '../../../../services/api'

import Button from '../../../../components/Button'
import CardInfo from '../../../../components/CardInfo'

import { useToast } from '../../../../hooks/toast'
import { useGameStats } from '../../../../hooks/gameStats'
import { useWorldStats } from '../../../../hooks/worldStats'
import { useMissionStats } from '../../../../hooks/missionStats'

import { Container, CardGame, CardArtigo } from './styles'

interface QuestionData {
  id: string
  articleQuizMissionId: string

  question: string
}

interface GameData {
  questions: QuestionData[]
}

interface ArticleQuizMission {
  id: string
  missionId: string
  article: string
}
interface OptionData {
  id: string
  isAnswer: boolean
  option: string
  articleQuizMissionQuestionId: string
}

const ArtigoQuiz: React.FC<GameData> = ({ questions }) => {
  const { missionId, worldId } = useParams()
  const { addToast } = useToast()

  const { setGameStats } = useGameStats()
  const { setWorldStats } = useWorldStats()
  const { setMissionStats } = useMissionStats()

  const [gameStarted, setGameStarted] = useState(false)

  const [ultimo, setUltimo] = useState(false)
  const [respostas, setRespostas] = useState<number[]>([])
  const [options, setOptions] = useState<OptionData[]>([])

  const [passouTodas, setPassouTodas] = useState(false)
  const [atual, setAtual] = useState(0)

  const [
    articleQuizMission,
    setArticleQuizMission,
  ] = useState<ArticleQuizMission | null>(null)

  useEffect(() => {
    async function loadArticleQuizMission(): Promise<void> {
      const response = await api.get(
        `/worlds/${worldId}/missions/${missionId}/article-quiz-mission`,
      )
      const { articleQuizMission: aqm } = response.data
      setArticleQuizMission(aqm)
    }

    loadArticleQuizMission()
  }, [missionId, worldId])

  const handleStart = useCallback(() => {
    setGameStarted(true)
  }, [])

  const loadOptions = useCallback(async (): Promise<void> => {
    if (gameStarted) {
      const response = await api.get(
        `/worlds/${worldId}/missions/${missionId}/questions/${questions[atual].id}/options`,
      )
      const { options: allOptions } = response.data

      setOptions(allOptions)
    }
  }, [gameStarted, worldId, missionId, questions, atual])

  useEffect(() => {
    loadOptions()
  }, [gameStarted, atual, loadOptions])

  const changeAtual = useCallback((): void => {
    if (passouTodas) {
      setUltimo(true)
    } else {
      if (atual + 2 >= questions.length) {
        setPassouTodas(true)
      }
      setAtual(state => state + 1)
    }
  }, [atual, passouTodas, questions])

  const handleNewAnswer = useCallback(
    async (answeredOptionId: string): Promise<void> => {
      const response = await api.post(
        `/worlds/${worldId}/missions/${missionId}/questions/${questions[atual].id}/answers`,
        { answer: answeredOptionId },
      )

      const { missionStats, userGameStats, worldStats } = response.data

      setGameStats(userGameStats)
      setWorldStats(worldStats)
      setMissionStats(missionStats)

      setRespostas(state => [...state, atual])

      changeAtual()
    },
    [
      atual,
      changeAtual,
      missionId,
      questions,
      setGameStats,
      setMissionStats,
      setWorldStats,
      worldId,
    ],
  )

  return (
    articleQuizMission && (
      <Container>
        <CardInfo
          h4="Esse jogo é um artigo com quiz."
          p="Leia o artigo antes de responder as perguntas."
        />
        {gameStarted ? (
          <CardGame>
            <h3>{questions[atual].question}</h3>
            <div>
              {options &&
                options.map(option => (
                  <button
                    type="button"
                    onClick={() => handleNewAnswer(option.id)}
                  >
                    {' '}
                    {option.option}
                  </button>
                ))}
            </div>
            <small>
              Você respondeu {respostas.length} de {questions.length} questões
            </small>
          </CardGame>
        ) : (
            <CardArtigo>
              <p>
                Leia o artigo abaixo e depois clique para responder as perguntas:
            </p>
              <a
                href={`${process.env.REACT_APP_API_URL}/files/${articleQuizMission.article}`}
                target="_blank"
              >
                Abrir artigo
            </a>
              <Button type="button" onClick={handleStart}>
                Responder perguntas
            </Button>
            </CardArtigo>
          )}
      </Container>
    )
  )
}

export default ArtigoQuiz
