
import videoPlatforms from '../config/videoPlatforms'

export default function getUrlVideo(videoPlatformId: string, video: string): string {

  let url = ''
  switch (videoPlatformId) {
    case videoPlatforms.vimeo:
      url = 'https://vimeo.com/' + video
      break;
    case videoPlatforms.youtube:
      url = 'https://www.youtube.com/watch?v=' + video
  }
  return url
}
