import React from 'react'

import { useToggleMenu } from '../../../../../hooks/toggleMenu'
import { Container } from './styles'

interface Props {
  open: boolean
  setOpen(open: boolean): void
}

const Burger: React.FC = () => {
  const { open, toggleMenu } = useToggleMenu()
  return (
    <Container open={open} onClick={toggleMenu}>
      <div />
      <div />
      <div />
    </Container>
  )
}

export default Burger
