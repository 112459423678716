import React, { useCallback, useRef } from 'react'
import {
  FiArrowLeft,
  FiMail,
  FiUser,
  FiLock,
  FiCalendar,
  FiPhone,
  FiTerminal,
} from 'react-icons/fi'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'

import { Link, useLocation, useHistory } from 'react-router-dom'

import * as Yup from 'yup'

import { useToast } from '../../hooks/toast'

import api from '../../services/api'

import getValidationErrors from '../../utils/getValidationErrors'

import { Container } from './styles'

import trilhaLogo from '../../assets/logo-mirador.png'

import Input from '../../components/Input'
import Button from '../../components/Button'

interface StateData {
  name: string
  email: string
}

interface SignUpFormDate {
  name: string
  email: string
  cpf: string
  password: string
  passwordConfirm: string
  birthdate: Date
  phone: string
  referenceCode?: string
}

const SignUp: React.FC = () => {
  const formRef = useRef<FormHandles>(null)

  const { state }: { state: StateData } = useLocation()
  const history = useHistory()
  const { addToast } = useToast()

  const handleSubmit = useCallback(
    async (data: SignUpFormDate) => {
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
          email: Yup.string()
            .required('E-mail é obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().min(6, 'No mínimo 6 caracteres'),
          passwordConfirm: Yup.string()
            .oneOf([Yup.ref('password')], 'As senhas digitadas não conferem')
            .required('Repita a senha'),
          cpf: Yup.string().required('CPF é obrigatório'),
          birthdate: Yup.date()
            .min(new Date(1900, 0, 1), 'Deve ser maior que 01/01/1900')
            .max(new Date(), 'Deve ser menor que a data de hoje.')
            .required('Data de nascimento obrigatória'),
          phone: Yup.string()
            .min(14, 'Deve ter ao menos 10 algarismos')
            .max(15, 'Deve ter até 11 algarismos'),
          referralCode: Yup.string(),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        await api.post('/users', data)
        addToast({
          type: 'success',
          title: 'Cadastro efetuado',
          description: 'Faça login para acessar a plataforma.',
        })

        history.push('/', { email: data.email })
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: err.response?.data?.message ?? err.message,
        })
      }
    },
    [addToast, history],
  )

  return (
    <Container>
      <header>
        <img src={trilhaLogo} alt="Trilha Certa" />
      </header>

      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{
          name: state?.name,
          email: state?.email,
        }}
      >
        <h1>Complete seu cadastro</h1>
        <Input name="name" icon={FiUser} type="text" placeholder="Nome" />
        <Input name="email" icon={FiMail} type="email" placeholder="E-mail" />
        <Input
          name="cpf"
          mask="cpf"
          icon={FiMail}
          type="tel"
          placeholder="CPF"
        />
        <Input
          name="birthdate"
          icon={FiCalendar}
          type="date"
          min="1900-00-01"
          placeholder="Data de nascimento"
        />
        <Input
          name="phone"
          icon={FiPhone}
          mask="phone"
          type="tel"
          placeholder="Telefone com DDD"
        />
        <Input
          name="password"
          icon={FiLock}
          type="password"
          placeholder="Senha"
        />
        <Input
          name="passwordConfirm"
          icon={FiLock}
          type="password"
          placeholder="Repetir Senha"
        />
        <Input
          name="referralCode"
          icon={FiTerminal}
          type="text"
          placeholder="Código de indicação"
        />

        <Button type="submit">Registrar</Button>
        <Link to="/">
          <FiArrowLeft size={20} />
          Voltar para o login
        </Link>
      </Form>
    </Container>
  )
}

export default SignUp
