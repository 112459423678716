import styled from 'styled-components';



export const Container = styled.div`


  border-radius:10px;


  min-height: 750px;
  height:100%;
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const CardFinalizou = styled.div`
  min-height: 500px;


  small {
    color: #666360;
    margin-top:8px;
  }

  > div {
    width: 600px;
    height: auto;
    border-radius: 10px;
    background-color: #f4ede8;
    display: flex;
    align-items: center;
    color: #232129;
    border: 5px solid rgba(49,46,56,0.15);
    position:relative;

    box-shadow: 2px 2px 8px rgba(0,0,0,0.5);
    position: relative;
    flex-direction:column;
    padding: 32px;




      h1 {
        font-size: 32px;
        text-align: center;
        text-transform:uppercase
      }
      p {
        font-size: 24px;
      }
      h3 {
        margin-bottom: 24px;
      }
  }
`
export const GameStats = styled.ul`
  list-style: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 32px 0;

  li{
    display: flex;
    align-items: center;
    img {
      width: 50px;
      margin-right: 16px;
    }
    font-size: 24px;
    font-weight: 700;

  }

`
