import React, { createContext, useCallback, useState, useContext } from 'react'

import api from '../services/api'

import { useWorldStats } from './worldStats'

interface MissionStats {
  points: number
  currentMissionId: string
  missionId: string
  userId: string
  id: string
  finalizedAt?: Date
}

interface MissionStatsContextData {
  missionStats: MissionStats

  setMissionStats(missionStats: MissionStats): void
  updateMissionStats(missionId: string): Promise<void>
  clearMissionStats(): void
}

const MissionStatsContext = createContext<MissionStatsContextData>(
  {} as MissionStatsContextData,
)

const MissionStatsProvider: React.FC = ({ children }) => {
  const { worldStats } = useWorldStats()
  const [data, setData] = useState<MissionStats>(() => {
    const missionStats = localStorage.getItem('@MiraGame:missionStats')

    if (missionStats) {
      return JSON.parse(missionStats)
    }
    return {} as MissionStats
  })

  const setMissionStats = useCallback(missionStats => {
    localStorage.setItem(
      '@MiraGame:missionStats',
      JSON.stringify(missionStats),
    )

    setData(missionStats)
  }, [])

  const updateMissionStats = useCallback(
    async (missionId: string) => {
      const response = await api.get(
        `/worlds/${worldStats.worldId}/missions/${missionId}/missionStats`,
      )
      setMissionStats(response.data.missionStats)
    },
    [setMissionStats, worldStats.worldId],
  )

  const clearMissionStats = useCallback(() => {
    localStorage.removeItem('@MiraGame:missionStats')
    setData({} as MissionStats)
  }, [])

  return (
    <MissionStatsContext.Provider
      value={{
        missionStats: data,
        setMissionStats,
        updateMissionStats,
        clearMissionStats,
      }}
    >
      {children}
    </MissionStatsContext.Provider>
  )
}

function useMissionStats(): MissionStatsContextData {
  const context = useContext(MissionStatsContext)
  if (!context) {
    throw new Error(
      'useMissionStats must be used within an MissionStatsProvider',
    )
  }
  return context
}

export { MissionStatsProvider, useMissionStats }
