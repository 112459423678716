import React, { useState, createContext, useCallback, useContext } from 'react'

interface ToggleMenu {
  title: string
  description?: string
  background?: string
}

interface ToggleMenuContextData {
  open: boolean

  toggleMenu(): void
}

const ToggleMenuContext = createContext<ToggleMenuContextData>(
  {} as ToggleMenuContextData,
)

const ToggleMenuProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState(false)

  const toggleMenu = useCallback(() => {
    setOpen(!open)
  }, [open])

  return (
    <ToggleMenuContext.Provider
      value={{
        open,
        toggleMenu,
      }}
    >
      {children}
    </ToggleMenuContext.Provider>
  )
}

function useToggleMenu(): ToggleMenuContextData {
  const context = useContext(ToggleMenuContext)
  if (!context) {
    throw new Error('useToggleMenu must be used within an ToggleMenuProvider')
  }
  return context
}

export { ToggleMenuProvider, useToggleMenu }
