import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: auto;
  background: #ebf8ff;
  color: #3172b7;
  border: 2px solid #3172b7;
  border-radius: 10px;
  padding:16px;
  margin-bottom:48px;
`;
