import React, { useCallback, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import missionTypes from '../../../config/missionTypes'

import { useToast } from '../../../hooks/toast'

import Anagram from './Anagram'
import VideoQuiz from './VideoQuiz'
import ArtigoQuiz from './ArtigoQuiz'
import Quiz from './Quiz'
import TextoLivre from './TextoLivre'

interface QuestionAnagramData {
  id: string
  missionId: string
  question: string
  nameShuffled: string
}

interface QuestionVideoQuizData {
  id: string
  missionId: string
  videoQuizMissionId: string
  question: string
}

interface QuestionFreeTextData {
  id: string
  missionId: string
  question: string
}

interface PlayGameData {
  missionId: string
  questions:
  | any
  | QuestionAnagramData[]
  | QuestionVideoQuizData[]
  | QuestionFreeTextData[]
  missionTypeId: string
}

const PlayGame: React.FC<PlayGameData> = ({
  missionId,
  questions,
  missionTypeId,
}) => {
  const { addToast } = useToast()
  const history = useHistory()
  const { worldId } = useParams()

  const selectGame = useCallback((): JSX.Element => {
    switch (missionTypeId) {
      case missionTypes.anagramaId: {
        return <Anagram questions={questions} />
      }
      case missionTypes.artigoQuizId: {
        return <ArtigoQuiz questions={questions} />
      }
      case missionTypes.quizId: {
        return <Quiz questions={questions} />
      }
      case missionTypes.palavraCruzadaId: {
        // fazer
        break
      }
      case missionTypes.textoLivreId: {
        return <TextoLivre questions={questions} />
      }
      case missionTypes.videoQuizId: {
        return <VideoQuiz questions={questions} />
      }
      default: {
        addToast({
          type: 'error',
          title: 'Erro ao carregar jogo.',
        })
        history.push(`/world/${worldId}`)
        return <h2>Falha ao carregar.</h2>
      }
    }
    return <h2>Falha ao carregar.</h2>
  }, [addToast, history, missionTypeId, questions, worldId])

  return <>{selectGame()}</>
}

export default PlayGame
