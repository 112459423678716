import React, { useContext, useCallback } from 'react'
import { FiPower, FiUser } from 'react-icons/fi'

import { GiPodium, GiMoon } from 'react-icons/gi'
import { FaHome } from 'react-icons/fa'

import OutsideClickHandler from 'react-outside-click-handler'

import Switch from 'react-switch'
import { Link } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

import { useAuth } from '../../../../../hooks/auth'
import { useToggleTheme } from '../../../../../hooks/toggleTheme'
import { useToggleMenu } from '../../../../../hooks/toggleMenu'

import { Container, BtnClose, List, ListItem, DarkModeMenu } from './styles'

const Menu: React.FC = () => {
  const { toggleTheme } = useToggleTheme()

  const { open, toggleMenu } = useToggleMenu()

  const { signOut } = useAuth()

  const { colors, title } = useContext(ThemeContext)

  const closeMenu = useCallback(() => {
    if (open) toggleMenu()
  }, [open, toggleMenu])

  return (
    <OutsideClickHandler onOutsideClick={closeMenu}>
      <Container open={open}>
        <List>
          <ListItem>
            <Link to="/dashboard" onClick={toggleMenu}>
              <FaHome size={30} />
              <span> Página inicial</span>
            </Link>
          </ListItem>
          <ListItem>
            <Link to="/profile" onClick={toggleMenu}>
              <FiUser size={30} />
              <span> Perfil</span>
            </Link>
          </ListItem>
          <ListItem>
            <Link to="/ranking" onClick={toggleMenu}>
              <GiPodium size={30} />
              <span> Ranking</span>
            </Link>
          </ListItem>

          <ListItem>
            <button type="button" onClick={signOut}>
              <FiPower size={30} />
              <span> Sair</span>
            </button>
          </ListItem>
        </List>
        <DarkModeMenu>
          <GiMoon size={30} />
          <span> Modo Escuro</span>
          <Switch
            onChange={toggleTheme}
            checked={title === 'dark'}
            checkedIcon={false}
            uncheckedIcon={false}
            height={10}
            width={40}
            handleDiameter={20}
            offColor={colors.tertiary}
            offHandleColor={colors.primary}
            onColor={colors.textSecondary}
            onHandleColor={colors.text}
          />
        </DarkModeMenu>
        <BtnClose type="button" onClick={toggleMenu}>
          <div />
          <div />
        </BtnClose>
      </Container>
    </OutsideClickHandler>
  )
}

export default Menu
