import styled from 'styled-components';

import { shade, tint } from 'polished'

interface ContainerProps {
  open: boolean
}
export const Container = styled.div<ContainerProps>`

  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${props => props.theme.colors.background};

  min-height: 200px;

  text-align: left;
  padding: 20px 40px;
  position: absolute;
  top: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? 'translateY(0)' : 'translateY(-100%)')};

  border-radius: 0px 0px 4px 4px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

  width: 100%;
  left: 0;
  right: 0;
  height: 100vh;

  @media(min-width: 500px) {
    height: unset;
    width: 350px;
    left: unset;
    right: 5px;

  }

`;

export const BtnClose = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  position: absolute;

  top:20px;
  right: 15px;
  background-color:transparent;
  padding:0;
  border:0;
  transition: all 0.3s linear;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }

  div {
    width: 35px;
    height: 5px;
    background: ${({ theme }) => theme.colors.text};
    border-radius: 15px;

    position: relative;
    transform-origin: 1px;

    & + div {
      margin-top: 3px;
    }

    :first-child {
      transform: rotate(50deg);
    }


    :nth-child(2) {
      margin-top:20px;
      transform: rotate(-50deg);
    }
  }
`
export const List = styled.div`


`

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  vertical-align: middle;
  padding: 10px 15px;



  a {
    text-decoration: none;
    color: ${ props => props.theme.colors.text};
    transition: color 0.2s;
    display: flex;
    align-items: center;
    margin-right: 30px;

    &:hover {
      color: ${ props => tint(0.2, props.theme.colors.text)};
    }

    svg {
      margin-right: 10px;
      height: 30px;
    }
  }
  button {
    border: none;
    background-color: transparent;
    padding: 0px;
    color: ${props => props.theme.colors.text};
    transition: color 0.2s;
    display: flex;
    align-items: center;
    &:hover {
      color: ${props => shade(0.2, props.theme.colors.text)};
    }

    svg {
      margin-right: 10px;
      height: 30px;
    }
  }

`


export const DarkModeMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px 15px;

  border-top: 1px solid ${props => props.theme.colors.backgroundSecondary};


  svg {
    color: ${props => props.theme.colors.text};
    margin-right: 10px;
    height: 30px;
  }

  span{
    flex: 1;
  }


`
