import React, { useEffect, useState, useCallback } from 'react'

import { useHistory, useLocation, useParams } from 'react-router-dom'

// eslint-disable-next-line import/no-duplicates
import { formatDistanceToNow, isAfter } from 'date-fns'

// eslint-disable-next-line import/no-duplicates
import { ptBR } from 'date-fns/locale'

import api from '../../services/api'

import Stars from './Stars'

import { Container, Card } from './styles'

import { useLayoutInfos } from '../../hooks/layoutInfos'
import { useToast } from '../../hooks/toast'
import { useWorldStats } from '../../hooks/worldStats'

interface StateData {
  world: WorldData
}

interface WorldData {
  id: string
  name: string
  startDate: Date
  endDate: Date
  icon: string
  background: string
}

interface MissionData {
  name: string
  id: string
  missionTypeId: string
  missionType: MissionTypeData
  missionStats: MissionStatsData
}
interface MissionStatsData {
  id: string
  missionId: string
  points: string
  timesPlayed: number
  userId: string
  finalizedAt: Date
}

interface MissionTypeData {
  name: string
  id: string
}

const World: React.FC = () => {
  const { worldId } = useParams()
  const { setLayoutInfos } = useLayoutInfos()
  const { setWorldStats, clearWorldStats, worldStats } = useWorldStats()
  const { addToast } = useToast()
  const history = useHistory()

  const { state }: { state: StateData } = useLocation()

  const [world, setWorld] = useState(state?.world)
  const [today] = useState(new Date())
  const [missions, setMissions] = useState<MissionData[]>([])

  useEffect(() => {
    if (world) {
      setLayoutInfos({
        background: world?.background,
        title: `Mundo ${world.name}`,
        description: `${
          isAfter(new Date(world.endDate), today) ? 'Termina ' : 'Terminou '
        }
      ${formatDistanceToNow(new Date(world.endDate), {
        addSuffix: true,
        locale: ptBR,
      })}`,
      })
    }
  }, [setLayoutInfos, world, today])

  useEffect(() => {
    async function loadWorld(): Promise<void> {
      const response = await api.get(`/worlds/${worldId}/missions`)

      setWorld(response.data.world)
    }

    loadWorld()
  }, [worldId])

  useEffect(() => {
    async function loadMissions(): Promise<void> {
      if (world) {
        try {
          const response = await api.get(`/worlds/${world.id}/missions`)
          const {
            worldStats: updatedWorldStats,
            missions: worldMissions,
          } = response.data

          const missionsWithStats: MissionData[] = await Promise.all(
            worldMissions.map(
              async (mission: MissionData): Promise<MissionData> => {
                const responseStats = await api.get(
                  `/worlds/${world.id}/missions/${mission.id}/missionStats`,
                )
                const { missionStats } = responseStats.data
                const newMission = mission
                newMission.missionStats = missionStats
                return newMission
              },
            ),
          )

          setMissions(missionsWithStats)
          setWorldStats(updatedWorldStats)
        } catch (err) {
          addToast({
            type: 'error',
            title: 'Erro ao carregar missões',
            description: 'Não foi possível carregar as missões do mundo. ',
          })
          clearWorldStats()
          history.push('/dashboard')
        }
      }
    }

    loadMissions()
  }, [addToast, clearWorldStats, history, setWorldStats, world])

  const handleStartGame = useCallback(
    missionId => {
      history.push(`/world/${world.id}/mission/${missionId}`)
    },
    [history, world],
  )

  return world ? (
    <Container>
      {missions.map((mission, index) => {
        const { timesPlayed, finalizedAt } = mission.missionStats
        const concluidos = finalizedAt ? timesPlayed : timesPlayed - 1

        return (
          <Card
            key={mission.id}
            isCurrent={mission.id === worldStats.currentMissionId}
            maxPlayedTimes={concluidos === 3}
          >
            <header>
              <div>
                <h1>{index + 1}</h1>
              </div>
            </header>
            <main>
              <h2>{mission.name}</h2>
              <p>{mission.missionType.name}</p>
            </main>
            <div>
              <Stars stars={concluidos} />
            </div>
            <button
              type="button"
              disabled={concluidos === 3}
              onClick={() => handleStartGame(mission.id)}
            >
              Jogar
            </button>
            {concluidos === 3 && (
              <p>Você atingiu o limite de jogadas dessa missão</p>
            )}
          </Card>
        )
      })}
    </Container>
  ) : (
    <br />
  )
}

export default World
