import styled from 'styled-components';

import { shade } from 'polished'

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  max-width: 990px;
  width: 80%;
  margin: 0 auto;
  padding-top:15px;

  color: ${props => props.theme.colors.text};

  header {
    justify-content: center;
    display:flex;

    img {
      max-width: 300px;
    }
  }


form{
  margin:15vh 0;
  flex:1;
  display:flex;
  flex-direction: column;
  text-align: center;

  h1{
    margin-bottom: 24px;
  }


  a {
    color: ${props => props.theme.colors.text};
    display: flex;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;
    align-items: center;
    justify-content: center;
    &:hover {
      color:${props => shade(0.2, props.theme.colors.text)}
    }

svg {
  margin-right: 8px;
}

  }
}
`
