import styled, { css } from 'styled-components'

import Tooltip from '../Tooltip'

interface ContainerProps {
  isFocused: boolean
  isFilled: boolean
  isErrored: boolean
}

export const Container = styled.div<ContainerProps>`
  background: ${props => props.theme.colors.secondary};
  border-radius: 10px;
  padding: 16px;
  display: flex;
  align-items: center;

  border: 2px solid ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.placeholder};

  & + div {
    margin-top: 8px;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: ${props => props.theme.colors.errorText};
    `}

  ${props =>
    props.isFocused &&
    css`
      border-color: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.primary};
    `}


  ${props =>
    props.isFilled &&
    css`
      color: ${props => props.theme.colors.primary};
    `}

  input {
  color: ${props => props.theme.colors.text};
    flex: 1;
    border: 0;
    background: transparent;

    &::placeholder {
      color: ${props => props.theme.colors.placeholder};
    }
  }
  svg {
    margin-right: 16px;
  }
`

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0%;
  }

  span {
    background: ${props => props.theme.colors.errorText};
    color: #fff;

    &::before {
      border-color: ${props => props.theme.colors.errorText} transparent;
    }
  }
`
