import React from 'react'

import { AuthProvider } from './auth'
import { ToastProvider } from './toast'
import { GameStatsProvider } from './gameStats'
import { MissionStatsProvider } from './missionStats'
import { WorldStatsProvider } from './worldStats'
import { LayoutInfosProvider } from './layoutInfos'

const AppProvider: React.FC = ({ children }) => (
  <GameStatsProvider>
    <WorldStatsProvider>
      <MissionStatsProvider>
        <AuthProvider>
          <ToastProvider>
            <LayoutInfosProvider>{children}</LayoutInfosProvider>
          </ToastProvider>
        </AuthProvider>
      </MissionStatsProvider>
    </WorldStatsProvider>
  </GameStatsProvider>
)
export default AppProvider
