import React, { useState } from 'react'

import { Link } from 'react-router-dom'

import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

import { useAuth } from '../../../../hooks/auth'
import { useGameStats } from '../../../../hooks/gameStats'
import { useWorldStats } from '../../../../hooks/worldStats'

import { Avatar } from '../../../../components'

import coinImage from '../../../../assets/coin.svg'
import diamondImage from '../../../../assets/diamond.svg'
import xpImage from '../../../../assets/xp.svg'

import {
  Container,
  DivAll,
  Content,
  Header,
  XpGameStats,
  GameStats,
  ItemGameStats,
  WorldStats,
  Dicas,
} from './styles'

const Sidebar: React.FC = () => {
  const { user } = useAuth()
  const { gameStats } = useGameStats()
  const { worldStats } = useWorldStats()

  const [showable, setShowable] = useState(false)

  return (
    <Container>
      <DivAll>
        <div>
          <button type="button" onClick={() => setShowable(!showable)}>
            Pontos, dicas e mais...
            {showable ? <FaChevronUp size={24} /> : <FaChevronDown size={24} />}
          </button>
        </div>
        <Content show={showable}>
          <div>
            <Header>
              <Link to="/profile">
                <Avatar
                  refCode={user?.referenceCode}
                  size={125}
                  avatar={user?.avatar}
                  name={user?.name}
                />
                <h3>{user?.name}</h3>
              </Link>
              <XpGameStats>
                <img src={xpImage} alt="XP" />
                {gameStats?.experience}
              </XpGameStats>
            </Header>
            <GameStats>
              <div>
                <ItemGameStats>
                  <img src={coinImage} alt="coin" />
                  {gameStats?.coins}
                </ItemGameStats>

                <ItemGameStats>
                  <img src={diamondImage} alt="diamond" />
                  {gameStats?.diamonds}
                </ItemGameStats>
              </div>
            </GameStats>
          </div>

          <div>
            {worldStats && worldStats.id && (
              <WorldStats>
                <p>Pontos nesse mundo</p>

                <span>{worldStats.points}</span>
              </WorldStats>
            )}
            <Dicas>
              <h2>Dicas</h2>
              <p>
                Compartilhe seu código de indicação{' '}
                <strong>{user?.referenceCode}</strong> para ganhar moedas quando
                seus amigos se cadastram no game!{' '}
              </p>
            </Dicas>
          </div>
        </Content>
      </DivAll>
    </Container>
  )
}

export default Sidebar
