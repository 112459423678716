import React from 'react'
import {
  RouteProps as ReactDOMRouterProps,
  Route as ReactDOMRoute,
  Redirect,
} from 'react-router-dom'

import { useAuth } from '../hooks/auth'

import Logged from '../pages/_layouts/Logged'

interface RouteProps extends ReactDOMRouterProps {
  isPrivate?: boolean
  component: React.ComponentType
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth()

  const PageRendered = isPrivate ? (
    <Logged>
      <Component />
    </Logged>
  ) : (
    <Component />
  )

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!user ? (
          PageRendered
        ) : (
          <Redirect
            to={{
              state: { from: location },
              pathname: isPrivate ? '/' : '/dashboard',
            }}
          />
        )
      }}
    />
  )
}

export default Route
