import React, { createContext, useCallback, useState, useContext } from 'react'

import api from '../services/api'

interface GameStats {
  experience: number
  coins: number
  diamonds: number
  id: string
}

interface GameStatsContextData {
  gameStats: GameStats

  setGameStats(gameStats: GameStats): void
  updateGameStats(): void
  clearGameStats(): void
}

const GameStatsContext = createContext<GameStatsContextData>(
  {} as GameStatsContextData,
)

const GameStatsProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<GameStats>(() => {
    const gameStats = localStorage.getItem('@MiraGame:gameStats')

    if (gameStats) {
      return JSON.parse(gameStats)
    }
    return {} as GameStats
  })

  const setGameStats = useCallback(gameStats => {
    localStorage.setItem('@MiraGame:gameStats', JSON.stringify(gameStats))

    setData(gameStats)
  }, [])

  const updateGameStats = useCallback(async () => {
    const response = await api.get('/user/gameStats')
    setData(response.data.gameStats)
  }, [])

  const clearGameStats = useCallback(() => {
    localStorage.removeItem('@MiraGame:gameStats')
    setData({} as GameStats)
  }, [])

  return (
    <GameStatsContext.Provider
      value={{ gameStats: data, setGameStats, updateGameStats, clearGameStats }}
    >
      {children}
    </GameStatsContext.Provider>
  )
}

function useGameStats(): GameStatsContextData {
  const context = useContext(GameStatsContext)
  if (!context) {
    throw new Error('useGameStats must be used within an GameStatsProvider')
  }
  return context
}

export { GameStatsProvider, useGameStats }
