import React from 'react'
import { Switch } from 'react-router-dom'

import Route from './Route'

import SignIn from '../pages/SignIn'
import SignUp from '../pages/SignUp'

import Dashboard from '../pages/Dashboard'
import Game from '../pages/Game'
import Profile from '../pages/Profile'
import Ranking from '../pages/Ranking'
import World from '../pages/World'

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/signup" component={SignUp} />

      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/profile" component={Profile} isPrivate />
      <Route path="/ranking" component={Ranking} isPrivate />
      <Route
        path="/world/:worldId/mission/:missionId"
        exact
        component={Game}
        isPrivate
      />
      <Route path="/world/:worldId" exact component={World} isPrivate />
    </Switch>
  )
}

export default Routes
