import styled from 'styled-components';

export const Container = styled.div`
  background: ${props => props.theme.colors.backgroundSecondary};
  background-size: cover;
  border-radius: 10px;
  flex: 1;
  padding: 12px;
  margin-top:12px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 990px) {
    margin: 0px;
    margin-left: 15px;
    padding: 20px;
  }


  @media screen and (min-width: 1200px) {

    margin-left: 25px;
    padding: 24px;
  }


  @media screen and (min-width: 1600px) {

    margin-left: 35px;
    padding: 28px;
  }
`;
